.app {
  display: flex;
  height: 100vh;
}

@media (max-width: 550px) {
  .app {
    flex-direction: column;
    height: auto;
  }
}