.sidebar {
  width: 200px;
  background-color: #f4f4f4;
  padding: 10px;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
  height: 100vh;
}

.sidebar button {
  display: block;
  width: 100%;
  padding: 10px;
  margin-bottom: 5px;
  background-color: #fff;
  border: 1px solid #ddd;
  cursor: pointer;
  text-align: left;
}

.sidebar button:hover {
  background-color: #e4e4e4;
}

@media (max-width: 550px) {
  .sidebar {
    width: auto;
  }
}