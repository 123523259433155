/* .book-slot-section {
  background-color: #000000;
  padding: 30px;
  text-align: center;
}

.book-slot-section h2 {
  font-size: 1.5rem;
  margin-bottom: 20px;
  color: white;
}

.form-group-inline {
  display: flex;
  justify-content: space-between;
}

.form-group {
  flex: 1;
  margin: 0 10px;
}

.form-group input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.book-slot-btn {
  background-color: #ffffff;
  color: #977656;
  padding: 10px 20px;
  border: 3px solid #977656;
  cursor: pointer;
}

.book-slot-btn:hover {
  background-color: #977656;
  color: white;
  border: 3px solid white;
}

.offline-course-section {
  background-color: #151515;
  padding: 30px;
  text-align: center;
  color: white;
}

.course-btn {
  background-color: white;
  color: #977656;
  padding: 10px 20px;
  border: 3px solid #977656;
  cursor: pointer;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.course-btn:hover {
  background-color: #977656;
  color: white;
  border: 3px solid white;
}

.offline-info {
  background-color: black;
  color: white;
  padding: 40px 20px;
  text-align: center;
}

.offline-info-heading {
  font-size: 2rem;
  margin-bottom: 30px;
}

.info-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  justify-content: center;
  padding-left: 60px;
  padding-right: 60px;
}

.info-item {
  text-align: center;
  background-color: #222;
  border: 2px solid white;
  padding: 20px;
  border-radius: 10px;
}

.info-item img {
  width: 100%;
  height: 400px;
  border-radius: 10px;
}

.info-item p {
  color: white;
  font-size: 1rem;
  margin-top: 10px;
}



.course-content {
  flex: 1;
  margin-top: 30px;
  margin-left: 30px;
  margin-right: 30px;
}

.offline-content {
  flex: 1;
  margin-top: 30px;
  margin-left: 60px;
  margin-right: 50px;
}

.course-boxes {
  display: flex;
  justify-content: space-around;
}

.course-box {
  flex: 1;
  margin: 0 10px;
  position: relative;
}

.course-img {
  margin-top: 30px;
  opacity: 0.5;
  width: 100%;
  border-radius: 10px;
}

.course-btn-online {
  background-color: white;
  color: #977656;
  padding: 10px 20px;
  border: 3px solid #977656;
  cursor: pointer;
  position: relative;
  left: 45%;
  transform: translate(-50%, -50%);
}

.course-btn-online:hover {
  background-color: #977656;
  color: white;
  border: 3px solid white;
} */


/* General Styles */
/* .book-slot-section {
  background-color: #000000;
  padding: 30px;
  text-align: center;
}

.book-slot-section h2 {
  font-size: 1.5rem;
  margin-bottom: 20px;
  color: white;
}

.form-group-inline {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.form-group {
  flex: 1;
  margin: 10px;
}

.form-group input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.book-slot-btn {
  background-color: #ffffff;
  color: #977656;
  padding: 10px 20px;
  border: 3px solid #977656;
  cursor: pointer;
}

.book-slot-btn:hover {
  background-color: #977656;
  color: white;
  border: 3px solid white;
}

.offline-course-section {
  background-color: #151515;
  padding: 30px;
  text-align: center;
  color: white;
}

.course-btn {
  background-color: white;
  color: #977656;
  padding: 10px 20px;
  border: 3px solid #977656;
  cursor: pointer;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.course-btn:hover {
  background-color: #977656;
  color: white;
  border: 3px solid white;
}

.offline-info {
  background-color: black;
  color: white;
  padding: 40px 20px;
  text-align: center;
}

.offline-info-heading {
  font-size: 2rem;
  margin-bottom: 30px;
}

.info-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  justify-content: center;
  padding-left: 60px;
  padding-right: 60px;
}

.info-item {
  text-align: center;
  background-color: #222;
  border: 2px solid white;
  padding: 20px;
  border-radius: 10px;
}

.info-item img {
  width: 100%;
  height: 400px;
  border-radius: 10px;
}

.info-item p {
  color: white;
  font-size: 1rem;
  margin-top: 10px;
}

.course-content {
  flex: 1;
  margin-top: 30px;
  margin-left: 30px;
  margin-right: 30px;
}

.offline-content {
  flex: 1;
  margin-top: 30px;
  margin-left: 60px;
  margin-right: 50px;
}

.course-boxes {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.course-box {
  flex: 1;
  margin: 20px;
  position: relative;
  min-width: 280px;
  max-width: 300px;
}

.course-img {
  margin-top: 30px;
  opacity: 0.5;
  width: 100%;
  border-radius: 10px;
}

.course-btn-online {
  background-color: white;
  color: #977656;
  padding: 10px 20px;
  border: 3px solid #977656;
  cursor: pointer;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}

.course-btn-online:hover {
  background-color: #977656;
  color: white;
  border: 3px solid white;
}


@media (max-width: 1024px) {
  .info-grid {
    grid-template-columns: repeat(2, 1fr);
    padding-left: 30px;
    padding-right: 30px;
  }

  .offline-info-heading,
  .book-slot-section h2 {
    font-size: 1.75rem;
  }

  .course-box {
    margin: 10px;
  }

  .course-box img {
    width: 100%;
    height: auto;
  }

  .form-group-inline {
    flex-direction: column;
    align-items: center;
  }

  .form-group {
    margin: 10px 0;
  }
}

@media (max-width: 768px) {
  .info-grid {
    grid-template-columns: 1fr;
    padding-left: 20px;
    padding-right: 20px;
  }

  .offline-info-heading,
  .book-slot-section h2 {
    font-size: 1.5rem;
  }

  .course-box {
    margin: 10px;
    max-width: 100%;
  }

  .form-group-inline {
    flex-direction: column;
    align-items: center;
  }

  .form-group {
    margin: 10px 0;
  }

  .course-btn,
  .course-btn-online {
    padding: 10px;
    font-size: 0.9rem;
  }
}

@media (max-width: 480px) {

  .offline-info-heading,
  .book-slot-section h2 {
    font-size: 1.25rem;
  }

  .course-box img {
    margin-top: 20px;
  }

  .form-group input {
    padding: 6px;
  }

  .book-slot-btn,
  .course-btn,
  .course-btn-online {
    padding: 8px;
    font-size: 0.8rem;
  }

  .info-item img {
    height: auto;
  }

  .info-item p {
    font-size: 0.9rem;
  }

  .form-group-inline {
    flex-direction: column;
  }

  .form-group {
    margin: 10px 0;
  }
} */



.book-slot-section {
  background-color: #000000;
  padding: 30px;
  text-align: center;
}

.book-slot-section h2 {
  font-size: 2rem;
  margin-bottom: 20px;
  color: white;
}

.book-slot-section h3 {
  color: white;
  padding-bottom: 20px;
}

.form-group-inline {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.form-group {
  flex: 1;
  min-width: 250px;
  margin: 10px;
}

.form-group input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.form-group select {
  width: 100%;
  height: 2.9rem;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  font-size: 16px;
  color: #333;
  background-color: #fff;
}

.form-group select:focus {
  border-color: #6c757d;
  outline: none;
}

.book-slot-btn {
  background-color: #ffffff;
  color: #977656;
  padding: 10px 20px;
  border: 3px solid #977656;
  cursor: pointer;
  margin-top: 10px;
}

.book-slot-btn:hover {
  background-color: #977656;
  color: white;
  border: 3px solid white;
}

.offline-course-section {
  /* background-color: #151515; */
  background-color: #000000;
  padding: 30px;
  text-align: center;
  color: white;
}

.course-boxes {
  display: flex;
  justify-content: space-around;
  /* flex-wrap: wrap; */
}

.booking-course-box {
  /* flex: 1; */
  margin: 20px;
  position: relative;
  min-width: 250px;
  max-width: 300px;
}

.course-img {
  margin-top: 30px;
  opacity: 0.5;
  width: 100%;
  border-radius: 10px;
}

.course-btn {
  background-color: white;
  color: #977656;
  padding: 10px 20px;
  border: 3px solid #977656;
  cursor: pointer;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.course-btn:hover {
  background-color: #977656;
  color: white;
  border: 3px solid white;
}

.booking-offline-info {
  background-color: black;
  color: white;
  padding: 40px 20px;
  text-align: center;
}

.booking-offline-info-heading {
  font-size: 2rem;
  margin-bottom: 30px;
}

.info-grid {
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  align-items: center;
}

.info-item {
  height: auto;
  text-align: center;
  /* background-color: #222; */
  /* border: 2px solid white; */
  padding: 20px;
  /* border-radius: 10px; */
}

.info-item img {
  width: 100%;
  height: auto;
  border-radius: 10px;
}

.info-item p {
  color: white;
  font-size: 1rem;
  margin-top: 10px;
}

.course-btn-online {
  background-color: white;
  color: #977656;
  padding: 10px 20px;
  border: 3px solid #977656;
  cursor: pointer;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}

.course-btn-online:hover {
  background-color: #977656;
  color: white;
  border: 3px solid white;
}

/* Media Queries for Responsiveness */

/* Tablets */
@media (max-width: 1024px) {

  .book-slot-section h2,
  .booking-offline-info-heading {
    font-size: 1.75rem;
  }

  .form-group-inline {
    flex-direction: column;
    align-items: center;
  }

  .form-group {
    margin: 10px 0;
  }

  .info-grid {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
}

/* Mobile Devices */
@media (max-width: 768px) {

  .book-slot-section h2,
  .booking-offline-info-heading {
    font-size: 1.5rem;
  }

  .booking-course-box img {
    width: 100%;
    height: auto;
  }

  .booking-course-box {
    margin: 10px;
  }

  .course-btn,
  .course-btn-online {
    padding: 10px;
    font-size: 0.9rem;
  }

  .info-grid {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 30vh;
  }

  .course-boxes {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

/* Small Mobile Devices */
@media (max-width: 480px) {

  .book-slot-section h2,
  .booking-offline-info-heading {
    font-size: 1.25rem;
  }

  .booking-course-box img {
    margin-top: 20px;
  }

  .form-group input {
    padding: 6px;
  }

  .book-slot-btn,
  .course-btn,
  .course-btn-online {
    padding: 8px;
    font-size: 0.8rem;
  }

  .info-item p {
    font-size: 0.9rem;
  }

  .info-grid {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: auto;
  }

  .info-grid .info-item {
    width: auto;
  }

}