/* .course-section {
  padding: 40px;
  background-color: #151515;
}

.course-container {
  display: flex;
  margin-left: 70px;
  margin-right: 70px;
  justify-content: space-between;
  align-items: center;
}

.course-info {
  flex: 1;
  padding-right: 20px;
}

.course-info h1 {
  font-size: 24px;
  color: white;
}

.course-info h3 {
  font-size: 16px;
  color: white;
}

.enroll-button {
  background-color: #ffffff;
  color: #977656;
  margin-top: 5px;
  padding: 10px 20px;
  border: 3px solid #977656;
  cursor: pointer;
}

.enroll-button:hover {
  background-color: #977656;
  color: white;
  border: 3px solid white;
}

.course-image {
  flex: 1;
  text-align: center;
}

.course-image img {
  width: 350px;
  height: 300px;
  border: 3px solid white;
}

.flip-card {
  background-color: transparent;
  width: 300px;
  height: 300px;
  perspective: 1000px;
  border: 3px solid white;
  margin-left: 200px;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.flip-card-front {
  background-color: #bbb;
  color: black;
}

.flip-card-back {
  background-color: #000000;
  color: white;
  transform: rotateY(180deg);
}

.course-selection-wrapper {
  background-color: black;
  color: white;
  padding: 30px 20px;
  text-align: center;
}

.course-selection-heading {
  font-size: 2rem;
  margin-bottom: 20px;
}

.course-selection-section {
  background-color: black;
  color: white;
  padding: 50px 20px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.course-box {
  flex: 1;
  margin: 0 10px;
  text-align: center;
  position: relative;
}

.course-box img {
  width: 350px;
  height: 300px;
  object-fit: cover;
  border: 3px solid white;
}

.course-box h3 {
  margin-bottom: 20px;
  font-size: 24px;
}

.course-buttons {
  display: flex;
  justify-content: center;
  padding: 20px 0;
  gap: 85px;
}

.course-buttons button {
  background-color: white;
  color: #977656;
  border: 2px solid #977656;
  padding: 10px 20px;
  cursor: pointer;
}

.course-buttons button:hover {
  background-color: #977656;
  color: white;
  border: 2px solid white;
}

.online-info {
  background-color: black;
  color: white;
  padding: 40px 20px;
  text-align: center;
}

.online-info-heading {
  font-size: 2rem;
  margin-bottom: 30px;
}

.info-grid-course {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  justify-content: center;
}

.info-item-course {
  text-align: center;
  background-color: #222;
  border: 2px solid white;
  padding: 20px;
  border-radius: 10px;
  height: 40rem;
}

.offline-info {
  background-color: black;
  color: white;
  padding: 40px 20px;
  text-align: center;
}


.offline-info-heading {
  font-size: 2rem;
  margin-bottom: 30px;
}

.info-item-off-course {
  text-align: center;
  background-color: #222;
  border: 2px solid white;
  padding: 20px;
  border-radius: 10px;
  height: 30rem;
} */


.course-section {
  padding: 40px;
  /* background-color: #151515;  */
  background-color: #000000;
}

.course-container {
  display: flex;
  flex-direction: column;
  margin: 0 20px;
  justify-content: center;
  align-items: center;
}

.course-info {
  flex: 1;
  text-align: center;
  padding: 20px;
}

.course-info h1 {
  font-size: 24px;
  color: white;
}

.course-info h3 {
  font-size: 16px;
  color: white;
}

.enroll-button {
  background-color: #ffffff;
  color: #977656;
  margin-top: 5px;
  padding: 10px 20px;
  border: 3px solid #977656;
  cursor: pointer;
}

.enroll-button:hover {
  background-color: #977656;
  color: white;
  border: 3px solid white;
}

.course-image {
  flex: 1;
  text-align: center;
  padding: 20px;
}

.course-image img {
  width: 100%;
  max-width: 350px;
  height: auto;
  /* border: 3px solid white; */
}

.flip-card {
  background-color: transparent;
  width: 100%;
  max-width: 300px;
  height: 300px;
  perspective: 1000px;
  /* border: 3px solid white; */
  margin: 0 auto;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.flip-card-front {
  background-color: #bbb;
  color: black;
}

.flip-card-back {
  background-color: #000000;
  color: white;
  transform: rotateY(180deg);
}

.course-selection-wrapper {
  background-color: black;
  color: white;
  padding: 30px 20px;
  text-align: center;
}

.course-selection-heading {
  font-size: 2rem;
  margin-bottom: 20px;
}

.course-selection-section {
  background-color: black;
  color: white;
  padding: 50px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.course-box {
  flex: 1;
  margin: 20px;
  text-align: center;
  position: relative;
  max-width: 90%;
}

.course-box img {
  width: 100%;
  height: auto;
  object-fit: cover;
  /* border: 3px solid white; */
}

.course-box h3 {
  margin-bottom: 20px;
  font-size: 24px;
}

.course-buttons {
  display: flex;
  justify-content: center;
  padding: 20px 0;
  gap: 20px;
}

.course-buttons button {
  background-color: white;
  color: #977656;
  border: 2px solid #977656;
  padding: 10px 20px;
  cursor: pointer;
}

.course-buttons button:hover {
  background-color: #977656;
  color: white;
  border: 2px solid white;
}

.online-info {
  background-color: black;
  color: white;
  padding: 40px 20px;
  text-align: center;
}

.online-info-heading {
  font-size: 2rem;
  margin-bottom: 30px;
}

.info-grid-course {
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
  justify-content: center;
}

.info-item-course {
  text-align: center;
  /* background-color: #222; */
  /* border: 2px solid white; */
  padding: 20px;
  /* border-radius: 10px; */
  height: auto;
}

.offline-info {
  background-color: black;
  color: white;
  padding: 40px 20px;
  text-align: center;
}

.offline-info-heading {
  font-size: 2rem;
  margin-bottom: 30px;
}

.info-item-off-course {
  text-align: center;
  /* background-color: #222; */
  /* border: 2px solid white; */
  padding: 20px;
  /* border-radius: 10px; */
  height: auto;
}

/* Media Queries for Responsiveness */
@media (min-width: 768px) {
  .course-container {
    flex-direction: row;
    margin: 0 70px;
  }

  .course-info {
    text-align: left;
    padding-right: 20px;
  }

  .course-selection-section {
    flex-direction: row;
    align-items: flex-start;
  }

  .course-buttons {
    gap: 85px;
  }

  .info-grid-course {
    grid-template-columns: repeat(3, 1fr);
  }

  .online-info .info-grid-course {
    display: flex;
  }

}

@media (max-width: 767px) {

  .course-container {
    display: grid;
  }
}