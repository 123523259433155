.customShadow {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.userTable {
  @apply bg-white;
}
.userTable th {
  @apply border text-base font-medium;
}
.userTable td {
  @apply border text-base text-center;
}
