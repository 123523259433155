@import url("https://fonts.googleapis.com/css2?family=Anta&family=Playfair+Display:ital,wght@0,400..900;1,400..900&display=swap");

* {
  margin: 0;
  padding: 0;
}

html,
body {
  font-family: "Anta", sans-serif;
}

@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/css/all.min.css");


/* header file  */

/* .top-bar {
  background-color: #151515;
  height: 35px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.left-content {
  padding: 10px;
  margin-left: 15px;
  color: #fff;
}

.right-content {
  padding: 10px;
  margin-right: 15px;
  color: #fff;
} */
/* .mobile-link::before {
  content: "\f10b";
  font-family: "Font Awesome 5 Free";
  margin-right: 5px;
}

.map-link::before {
  content: "\f3c5";
  font-family: "Font Awesome 5 Free";
  margin-right: 5px;
}

.mobile-link::before,
.map-link::before {
  color: #fdfdfd;
  margin-right: 5px;
}

.mobile-link,
.map-link {
  color: #fff;
  text-decoration: none;
  margin-right: 10px;
}

.mobile-link:hover,
.map-link:hover {
  text-decoration: underline;
} */

/* .navbar {
  background-color: #000000;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  z-index: 100;
  position: relative;
  align-items: center;
} */

/* .navbar-left img {
  width: 100px;
  height: 60px;
}

.navbar-center {
  display: flex;
}

.navbar-center .nav-link {
  color: #fff;
  text-decoration: none;
  margin: 0 20px;
  transition: color 0.3s;
}

.navbar-center .nav-link:hover {
  color: #977656;
  text-decoration: underline;
}

.navbar-right a {
  background-color: transparent;
  color: #fff;
  text-decoration: none;
  padding: 8px 16px;
  border: 1px solid #fff;
  border-radius: 4px;
  margin: 0 10px;
  transition: background-color 0.3s, color 0.3s;
}

.navbar-right a:hover {
  color: #977656;
} */

/* #hamburger-nav {
  display: none;
}

.hamburger-menu {
  position: relative;
  display: inline-block;
}

.hamburger-icon {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 1.5rem;
  width: 1.875rem;
  cursor: pointer;
}

.hamburger-icon span {
  width: 100%;
  height: 0.125rem;
  background-color: white;
  transition: all 0.3 ease-in-out;
} */

/* .menu-links {
  position: absolute;
  top: 100%;
  right: 0;
  background-color: rgb(108, 108, 108);
  width: fit-content;
  max-height: 0;
  overflow: hidden;
  transition: all 0.3 ease-in-out;
}

.menu-links a {
  display: block;
  padding: 0.625rem;
  text-align: center;
  font-size: 1.5rem;
  color: rgb(0, 0, 0);
  text-decoration: none;
  transition: all 0.3 ease-in-out;
}

.menu-links li {
  list-style: none;
}

.menu-links.open {
  max-height: 18.75rem;
} */

/* .hamburger-icon.open span:first-child {
  transform: rotate(45deg) translate(0.625rem, 0.3125rem);
}

.hamburger-icon.open span:nth-child(2) {
  opacity: 0;
}

.hamburger-icon.open span:last-child {
  transform: rotate(-45deg) translate(0.625rem, -0.3125rem);
}

.hamburger-icon span:first-child {
  opacity: 1;
}

.hamburger-icon span:first-child {
  transform: none;
} */


/* home.js */

/* .background-section {
  position: relative;
  height: 900px;
  background-image: url("../../assets/bg1.jpg");
  background-size: cover;
  background-position: center;
}

.background-section::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
}

.slider-container {
  position: relative;
  width: 80%;
  margin: 0 auto;
  padding-top: 130px;
  height: 500px;
  display: flex;
  flex-direction: column;
  z-index: 1;
  justify-content: center;
  align-items: center;
}

.slider {
  position: relative;
  width: 100%;
  height: 400px;
  max-width: 800px;
  overflow: hidden;
  margin-top: 90px;
}

.slide {
  flex: 0 0 100%;
  overflow: hidden;
  border: 5px solid white;
  height: 400px;
  box-sizing: border-box;
}

.slide img {
  width: 100%;
  height: 400px;
  display: block;
  object-fit: unset;
  box-sizing: border-box;
}

.slider-navigation {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.dots {
  width: 10px;
  height: 10px;
  background-color: #ccc;
  margin: 0 5px;
  cursor: pointer;
}

.active {
  background-color: #977656;
}


.button-container {
  margin-top: 20px;
  text-align: center;
}

.rectangle-button {
  background-color: white;
  color: #977656;
  padding: 10px 20px;
  border: 2px solid #977656;
  cursor: pointer;
  transition: background-color 0.3s;
}

.rectangle-button:hover {
  background-color: #7e5e3d;
  color: #fff;
  border: 2px solid white;
}


.rectangle-container {
  background-color: #f0f0f0;
  padding: 20px;
  border-radius: 0px;
  margin-top: 20px;
  text-align: center;
}

.rectangle-container h2 {
  color: #333;
  font-size: 24px;
  text-align: center;
}

.rectangle-container p {
  color: #666;
  margin-bottom: 15px;
}

.video-boxes {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.video-box {
  width: calc(25% - 10px);
  background-color: #000000;
  height: 300px;
}

.about-section {
  position: relative;
  background-color: #151515;
  color: #fff;
  padding: 50px 0;
  text-align: center;
}

.about-section h2 {
  text-align: center;
  font-size: 28px;
  margin-bottom: 20px;
}

.about-section p {
  padding: 0 50px;
  font-size: 16px;
  margin-bottom: 30px;
}

.image-boxes {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.center-image {
  display: block;
  margin: 0 auto;
}

.image-box {
  margin: 10px;
  text-align: center;
  border: 2px solid #fff;
  padding: 10px;
  width: 200px;
}

.image-box img {
  width: 100px;
  height: 100px;
}

.image-box p {
  font-size: 13px;
  margin-top: 10px;
}

.about-section h3 {
  font-size: 24px;
  margin-top: 30px;
}

.about-section p {
  margin-bottom: 20px;
}

.medium-boxes {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
  padding: 0 10%;
}

.medium-box img {
  width: 100%;
  height: 350px;
  border-radius: 10px 10px 0 0;
}

.medium-box h4 {
  font-size: 18px;
  margin-bottom: 10px;
  text-align: center;
}

.medium-box p {
  font-size: 14px;
  text-align: center;
}

.medium-box {
  text-align: center;
  border: 2px solid #fff;
  padding: 20px;
  border-radius: 10px;
  width: 30%;
  margin-top: 20px;
}

.intro-section {
  background-color: black;
  color: white;
  padding: 40px 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.intro-image {
  flex: 0 0 auto;
  margin-right: 100px;
}

.intro-image img {
  width: 200px;
  transition: transform 0.3s ease, filter 0.3s ease;
  border: 5px solid white;
  filter: grayscale(100%);
}

.intro-image img:hover {
  transform: scale(1.1);
  border-color: #7e5e3d;
  filter: grayscale(0%);
}

.intro-content {
  max-width: calc(70% - 220px);
}

.intro-content h2 {
  font-size: 24px;
  margin-bottom: 10px;
  margin-top: 0;
}

.intro-content p {
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 15px;
  margin-top: 0%;
}

.slider-section {
  background-color: #151515;
  padding: 40px;
  overflow: hidden;
}

.custom-slider {
  width: 100%;
  position: relative;
}

.slides {
  display: flex;
  overflow: hidden;
  width: calc(400px * 10 + 80px * 9);
  animation: slideAnimation 30s linear infinite;
}

.slides img {
  height: 400px;
  width: 400px;
  margin-right: 80px;
}

@keyframes slideAnimation {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(
        -3500px);
  }
} */



/* General Styles */

.background-section {
  position: relative;
  height: 900px;
  background-image: url("../../assets/bg1.jpg");
  background-size: cover;
  background-position: center;
}

.background-section::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
}

.slider-container {
  position: relative;
  width: 80%;
  margin: 0 auto;
  padding-top: 130px;
  height: 500px;
  display: flex;
  flex-direction: column;
  z-index: 1;
  justify-content: center;
  align-items: center;
}

.slider {
  position: relative;
  width: 100%;
  height: 400px;
  max-width: 800px;
  overflow: hidden;
  margin-top: 90px;
}

.slide {
  flex: 0 0 100%;
  overflow: hidden;
  border: 2px solid white;
  height: 400px;
  box-sizing: border-box;
}

.slide img {
  width: 100%;
  height: 100%;
  display: block;
  object-fit: unset;
  box-sizing: border-box;
}

.slider-navigation {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.dots {
  width: 10px;
  height: 10px;
  background-color: #ccc;
  margin: 0 5px;
  cursor: pointer;
}

.active {
  background-color: #977656;
}

.button-container {
  margin-top: 20px;
  text-align: center;
}

.rectangle-button {
  background-color: white;
  color: #977656;
  padding: 10px 20px;
  border: 2px solid #977656;
  cursor: pointer;
  transition: background-color 0.3s;
}

.rectangle-button:hover {
  background-color: #7e5e3d;
  color: #fff;
  border: 2px solid white;
}

.rectangle-container {
  /* background-color: #f0f0f0; */
  background-color: black;
  padding: 20px;
  border-radius: 0px;
  margin-top: 20px;
  text-align: center;
}

.rectangle-container h2 {
  /* color: #333; */
  color: white;
  font-size: 24px;
  text-align: center;
}

.rectangle-container p {
  /* color: #666; */
  color: white;
  margin-bottom: 15px;
}

.video-boxes {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 20px;
}

.video-box {
  width: calc(25% - 10px);
  background-color: #000000;
  height: 300px;
  margin-bottom: 10px;
}

.about-section {
  position: relative;
  /* background-color: #151515; */
  background-color: #000000;
  color: #fff;
  padding: 50px 0;
  text-align: center;
}

.about-section h2 {
  text-align: center;
  font-size: 28px;
  margin-bottom: 20px;
}

.about-section p {
  padding: 0 50px;
  font-size: 16px;
  margin-bottom: 30px;
}

.image-boxes {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.center-image {
  display: block;
  margin: 0 auto;
}

.image-box {
  margin: 10px;
  text-align: center;
  /* border: 2px solid #fff; */
  padding: 10px;
  width: 200px;
}

.image-box img {
  width: 100px;
  height: 100px;
}

.image-box p {
  font-size: 13px;
  margin-top: 10px;
  padding: 0;
}

.about-section h3 {
  font-size: 24px;
  margin-top: 30px;
}

.about-section p {
  margin-bottom: 20px;
}

.medium-boxes {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
  padding: 0 10%;
}

.medium-box img {
  width: 100%;
  height: 350px;
  border-radius: 10px 10px 0 0;
}

.medium-box h4 {
  font-size: 18px;
  margin-bottom: 10px;
  text-align: center;
}

.medium-box p {
  font-size: 14px;
  text-align: center;
}

.medium-box {
  text-align: center;
  /* border: 2px solid #fff; */
  padding: 20px;
  /* border-radius: 10px; */
  width: 30%;
  margin-top: 20px;
}

.intro-section {
  background-color: black;
  color: white;
  padding: 40px 50px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.intro-image {
  flex: 0 0 auto;
  /* margin-right: 100px; */
}

.intro-image img {
  width: 200px;
  transition: transform 0.3s ease, filter 0.3s ease;
  /* border: 5px solid white; */
  filter: grayscale(100%);
}

.intro-image img:hover {
  transform: scale(1.1);
  /* border-color: #7e5e3d; */
  filter: grayscale(0%);
}

.intro-content {
  max-width: calc(70% - 220px);
}

.intro-content h2 {
  font-size: 24px;
  margin-bottom: 10px;
  margin-top: 0;
}

.intro-content p {
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 15px;
  margin-top: 0;
}

.slider-section {
  background-color: #151515;
  padding: 40px;
  overflow: hidden;
}

.custom-slider {
  width: 100%;
  position: relative;
}

.slides {
  display: flex;
  overflow: hidden;
  width: calc(400px * 10 + 80px * 9);
  animation: slideAnimation 30s linear infinite;
}

.slides img {
  height: 400px;
  width: 400px;
  margin-right: 80px;
}

@keyframes slideAnimation {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-3300px);
  }
}

/* Responsive Styles */
@media (max-width: 1200px) {
  .slider-container {
    width: 95%;
  }

  .rectangle-container h2 {
    font-size: 20px;
  }

  .medium-box {
    width: calc(50% - 40px);
  }

  .intro-section {
    padding: 40px 10px;
    flex-direction: column;
    align-items: center;
  }

  .intro-image {
    margin-right: 0;
    margin-bottom: 20px;
  }

  .intro-content {
    max-width: 100%;
    text-align: center;
  }

  .intro-content h2 {
    font-size: 20px;
  }

  .intro-content p {
    font-size: 14px;
  }

  .slides img {
    height: 300px;
    width: 300px;
    margin-right: 40px;
  }
}

@media (max-width: 768px) {
  .slider-container {
    width: 100%;
  }

  .rectangle-button {
    padding: 10px 15px;
  }

  .rectangle-container h2 {
    font-size: 18px;
  }

  .image-box {
    width: calc(50% - 20px);
  }

  .intro-content h2 {
    font-size: 20px;
  }

  .intro-content p {
    font-size: 14px;
  }

  .medium-box {
    width: calc(100% - 20px);
    margin-bottom: 20px;
  }

  .intro-section {
    flex-direction: column;
    align-items: center;
  }

  .medium-boxes {
    flex-direction: column;
    align-items: center;
    padding: 0;
  }

  .slides img {
    height: 250px;
    width: 250px;
    margin-right: 20px;
  }
}

@media (max-width: 480px) {
  .background-section {
    height: 700px;
    margin-top: 41%;
  }

  .rectangle-container {
    padding: 15px;
  }

  .video-boxes {
    justify-content: center;
    flex-wrap: wrap;
  }

  .video-box {
    width: calc(50% - 10px);
    margin-bottom: 10px;
  }

  .image-box {
    width: 100%;
    max-width: 150px;
    margin: 10px auto;
  }

  .intro-content h2 {
    font-size: 18px;
  }

  .intro-content p {
    font-size: 12px;
  }

  .medium-box {
    width: 100%;
    margin-bottom: 20px;
  }

  .intro-section {
    flex-direction: column;
    align-items: center;
  }

  .medium-box img {
    height: 200px;
  }

  .slides img {
    height: 200px;
    width: 200px;
    margin-right: 10px;
  }

  .about-section {
    margin-top: 75%;
  }
}




/* footer css */

/* .footer {
  background-color: #000;
  color: #fff;
  padding: 20px 50px;
}

.footer-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-left: 80px;
}

.footer-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
} */

/* .contact,
.social-media,
.map {
  flex-basis: calc(33.33% - 20px);
  max-width: calc(33.33% - 20px);
} */

/* .social-icons a {
  color: #fff;
  text-decoration: none;
  margin-right: 10px;
} */

/* .footer-divider {
  border-top: 1px solid #fff;
  margin: 30px 0;
  width: 90%;
  justify-content: center;
} */

/* .bottom-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  padding: 0 50px;
} */

/* .logo img {
  width: 100px;
  height: auto;
}

.created-by {
  text-align: center;
  flex: 1;
}

.created-by a {
  color: #fff;
  text-decoration: underline;
} */

/* .footerimage {
  text-align: right;
}

.footerimage img {
  height: 150px;
  filter: invert();
} */

/*COURSE PAGE CSS */

/* .course-section {
  padding: 40px;
  background-color: #151515;
} */

/* .course-container {
  display: flex;
  margin-left: 70px;
  margin-right: 70px;
  justify-content: space-between;
  align-items: center;
} */

/* .course-info {
  flex: 1;
  padding-right: 20px;
}

.course-info h1 {
  font-size: 24px;
  color: white;
}

.course-info h3 {
  font-size: 16px;
  color: white;
} */

/* .enroll-button {
  background-color: #ffffff;
  color: #977656;
  margin-top: 5px;
  padding: 10px 20px;
  border: 3px solid #977656;
  cursor: pointer;
}

.enroll-button:hover {
  background-color: #977656;
  color: white;
  border: 3px solid white;
} */

/* .course-image {
  flex: 1;
  text-align: center;
}

.course-image img {
  width: 350px;
  height: 300px;
  border: 3px solid white;
} */

/* .course-selection-wrapper {
  background-color: black;
  color: white;
  padding: 30px 20px;
  text-align: center;
} */

/* .course-selection-section {
  background-color: black;
  color: white;
  padding: 50px 20px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
} */

/* .course-selection-heading {
  font-size: 2rem;
  margin-bottom: 20px;
} */

/* .course-box {
  flex: 1;
  margin: 0 10px;
  text-align: center;
}

.course-box img {
  width: 350px;
  height: 300px;
  object-fit: cover;
  border: 3px solid white;
}

.course-box h3 {
  margin-bottom: 20px;
  font-size: 24px;
} */

/* .course-buttons {
  display: flex;
  justify-content: center;
  padding: 20px 0;
  gap: 85px;
}

.course-buttons button {
  background-color: white;
  color: #977656;
  border: 2px solid #977656;
  padding: 10px 20px;
  cursor: pointer;
}

.course-buttons button:hover {
  background-color: #977656;
  color: white;
  border: 2px solid white;
} */

/* General styling for the online info section */
/* .online-info {
  background-color: black;
  color: white;
  padding: 40px 20px;
  text-align: center;
} */

/* Styling for the main heading in the online info section */
/* .online-info-heading {
  font-size: 2rem;
  margin-bottom: 30px;
} */

/* .offline-info {
  background-color: black;
  color: white;
  padding: 40px 20px;
  text-align: center;
}


.offline-info-heading {
  font-size: 2rem;
  margin-bottom: 30px;
} */

/* Grid layout for displaying the images and descriptions */
/* .info-grid {
  display: grid; 
  grid-template-columns: repeat(3, 1fr); 
  gap: 20px; 
  justify-content: center;
  padding-left: 60px;
  padding-right: 60px;
} */

/* Styling for each info item in the grid */
/* .info-item {
  text-align: center;
  background-color: #222;
  border: 2px solid white;
  padding: 20px;
  border-radius: 10px;
} */

/* Styling for images within each info item */
/* .info-item img {
  width: 100%;
  height: 400px;
  border-radius: 10px;
} */

/* Styling for the text within each info item */
/* .info-item p {
  color: white;
  font-size: 1rem;
  margin-top: 10px;
} */

/*BOOKING SLOT PAGE CSS*/
/* Section 1: Book a Haircut Slot */
/* .book-slot-section {
  background-color: #000000;
  padding: 30px;
  text-align: center;
}

.book-slot-section h2 {
  font-size: 1.5rem;
  margin-bottom: 20px;
  color: white;
} */

/* .form-group-inline {
  display: flex;
  justify-content: space-between;
} */

/* .form-group {
  flex: 1;
  margin: 0 10px;
}

.form-group input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 5px;
} */

/* .book-slot-btn {
  background-color: #ffffff;
  color: #977656;
  padding: 10px 20px;
  border: 3px solid #977656;
  cursor: pointer;
}

.book-slot-btn:hover {
  background-color: #977656;
  color: white;
  border: 3px solid white;
} */

/* Section 2: Offline Courses */
/* .offline-course-section {
  background-color: #151515;
  padding: 30px;
  text-align: center;
  color: white;
} */

/* .course-boxes {
  display: flex;
  justify-content: space-around;
} */

/* .course-box {
  flex: 1;
  margin: 0 10px;
  position: relative;
} */

/* .course-img {
  margin-top: 30px;
  opacity: 0.5;
  width: 100%;
  border-radius: 10px;
} */



/* .course-boxes {
  display: flex;
  justify-content: space-around;
} */

/* .course-box {
  flex: 1;
  margin: 0 10px;
  position: relative;
} */

/* .course-img {
  margin-top: 30px;
  opacity: 0.5 black;
  width: 100%;
  border-radius: 10px;
} */

/* .course-btn {
  background-color: white;
  color: #977656;
  padding: 10px 20px;
  border: 3px solid #977656;
  cursor: pointer;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.course-btn:hover {
  background-color: #977656;
  color: white;
  border: 3px solid white;
} */


/* .flip-card {
  background-color: transparent;
  width: 300px;
  height: 300px;
  perspective: 1000px;
  border: 3px solid white;
  margin-left: 200px;
} */

/* .flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
} */

/* .flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
} */

/* .flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.flip-card-front {
  background-color: #bbb;
  color: black;
}

.flip-card-back {
  background-color: #000000;
  color: white;
  transform: rotateY(180deg);
} */